
































































//TODO: Fix smart-number-input for taxes
import Vue from 'vue';
import ListExpenseContainer from '@/components/constructor-product-tab/ListExpenseContainer.vue';
import channelManager from '@/common/classes/channel.manager';
import productManager from '@/common/classes/product.manager';
import { smartToFixed, numberWithSpaces } from '@/common/misc';
import { mapGetters } from 'vuex';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import TaxUtils from '@/common/classes/tax.utils';
import { getProductTaxesMap } from '@/common/classes/tax.utils';
import ChartMethods from '@/components/mixins/ChartMethods.vue';
import { ProductTax } from '@/common/types/product.types';

export default Vue.extend({
    name: 'specialExpenses',
    mixins: [ChartMethods, MiscMixin],
    components: {
        ListExpenseContainer,
    },
    props: {
        area: {},
    },
    methods: {
        showTaxContainer(areaType:number,tax:ProductTax){
            const area = this.area as any;
            if(area.isCollapsed()) return false;
            return area.type == areaType && this.product.useStructure && TaxUtils.isTaxActive( tax );
        },
    },
    computed: {
        showTaxUnitExpenseContainer:  {
            get():boolean {
                return this.showTaxContainer(0, getProductTaxesMap().avgCheckTax);
            },
            cache: false,
        },
        showTaxConstExpenseContainer: {
            get():boolean {
                return this.showTaxContainer(1, getProductTaxesMap().constTax);
            },
            cache: false
        },
        showclientsPerSalesUnit():boolean { return productManager.showclientsPerSalesUnit(); },
        displayMarketingCost():number{
            return channelManager.getAvgSingleSaleCost();
        },
        displayMarketingCostOneClient():number{
            return channelManager.getAvgSingleSaleCost() / this.product.clientsPerSalesUnit;
        },
        displayAvgCheckBeforeTax():number{
            return this.product.avgCheck;
        },
        displayAvgCheckTax():number{
            return TaxUtils.getUnitTaxCost(this.product.avgCheck * this.product.clientsPerSalesUnit);
        },
        displayConstBeforeTax(this:any):number{
            return this.monthlyProfitNoTax;
        },
        displayConstTax(this:any):number{
            return this.monthlyTaxCost;
        },
        ...mapGetters(['product']),
    },
    directives: {
        fragments: {
            inserted(el):void {
                const children = Array.from(el.children)
                const parent = el.parentElement;
                if(parent == null) return;
                children.forEach((item) => { parent.appendChild(item) })
                parent.removeChild(el)
            }
        }
    },
    filters: {
        smartToFixed,
        numberWithSpaces,
    }
})
