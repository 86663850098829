
import Vue from 'vue';
import { Expense } from '@/common/classes/expenses';
import { getExpensePresets, getIndustries, Industry } from '@/common/config';
import { mapGetters } from 'vuex';

export default Vue.extend({
    name: 'expensePresetMixin',
    // data(){
    //     return{
    //         industries: getIndustries()
    //     }
    // },
    computed: {
        currentIndustry():Industry{
            return getIndustries()[this.product.industry];
        },
        expensePresets():readonly Expense[]{
          return [...getExpensePresets(), ...(this.currentIndustry ? this.currentIndustry.presets : [])];
        },
        ...mapGetters(['product'])
    }
})
