












































import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
    name: 'listExpenseContainer',
    props: {
        hideUnusedSlots: {type:Boolean,default:false},
        showContextMenuButton: {type:Boolean,default:false},
    },
    methods: {
        async contextMenuButtonClick(e:MouseEvent):Promise<void> {
            await new Promise((r) => setTimeout(r, 30))
            const menuElement = e.target as HTMLElement;
            const expenseElement = this.$refs.listExpenseRoot as HTMLElement;
            const createMouseEvent = (name:string, el:HTMLElement) => new MouseEvent(name, {
                bubbles: true,
                cancelable: false,
                view: window,
                button: 2,
                buttons: 2,
                clientX: el.getBoundingClientRect().x,
                clientY: el.getBoundingClientRect().y
            })
            
            const events = [
                "mousedown",
                "mouseup",
                "contextmenu",
                "mouseover",
                "mousemove",
            ];
            expenseElement.dispatchEvent(createMouseEvent("mouseenter", expenseElement));
            events.map((type) => createMouseEvent(type, menuElement)).forEach((e) => menuElement.dispatchEvent(e));

            // this.$emit('contextmenu-button-click', e);
        },
    },
    computed: {
        ...mapGetters(['product'])
    }
})
