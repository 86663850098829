
























import Vue from 'vue';
import { Unit } from '@/common/classes/unit';
import UnitManager, {defExpTypes} from '@/common/classes/unit.manager';
import { mapGetters } from 'vuex';
import { isMobile } from '@/common/misc';
import MiscMixin from '@/components/mixins/MiscMixin.vue';

export default Vue.extend({
    name: 'projectUnitsModal',
    data(){
        return{
            newUnitName: ''
        }
    },
    mixins: [MiscMixin],
    methods: {
        hideModal():any{
            this.$modal.hide('project-units');
        },
        addUnit(name:string,e:any):void{
            this.newUnitName='';
            UnitManager.addUnit(new Unit(name,defExpTypes));
        },
        deleteUnit(unit:Unit):void{ UnitManager.deleteUnit(unit); }
    },
    computed: {
        modalWidth():number{
            return isMobile() ? 410 : 430;
        },
        modalHeight():number{
            return Math.min( window.screen.height - 40, isMobile() ? 280 : 460 );
        },
        displayUnits: {
            get():Unit[]{ return this.units.filter((u:Unit) => !['%','мес'].includes(u.text)); },
            cache: false
        },
        ...mapGetters(['units'])
    }  
})
