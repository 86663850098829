



























































































































































































import UnitManager from '@/common/classes/unit.manager';
import {Expense,CostExpense,UnitExpense,RefExpense} from '@/common/classes/expenses';
import {ExpenseReference,NumberReference, Referencer} from '@/common/classes/referencer';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import ExpensePresetsMixin from '@/components/mixins/ExpensePresetsMixin.vue';
import {mapGetters} from 'vuex';
import {smartToFixed,numberWithSpaces,escapeRegExp, jsonCycler, isMobile} from '@/common/misc';
import {Unit} from '@/common/classes/unit';
import Vue from 'vue';
import ListExpenseContainer from './ListExpenseContainer.vue';

//TODO: disable active input border
const debug = false;

//TODO: Show cost/expense type-aware tooltips
export default Vue.extend({
    name: 'listExpense',
    props: {
        propExpense: {type: Expense},
        setExpenseType: {type: Number},
        isAdd: {type:Boolean},
        menterCB: {}
    },
    mixins: [MiscMixin,ExpensePresetsMixin],
    components: {ListExpenseContainer},
    data(){
        return{
            debug,
            smartToFixed,
            numberWithSpaces,
            isNameInputFocused: false,
        }
    },
    watch: {
        //FIXME: Select values are reset after one of the units is deleted
        async expenseUnits(newUnits:Unit[], oldUnits:Unit[]){
            const unitWasAdded = newUnits.length - oldUnits.length == 1;
            const unitWasDeleted = newUnits.length - oldUnits.length == -1;
            const isUnitExpense = this.expenseStrType == 'unit';
            
            const unitRefSelect = this.$refs.unitRefSelect as any;
            if( isUnitExpense ) {
                await Vue.nextTick();
                if(!unitRefSelect || unitRefSelect.selectedIndex == 0) return;
                if(unitWasAdded)
                    unitRefSelect.selectedIndex += 1;
                if(unitWasDeleted){
                    const units = newUnits.map(({text})=>text)
                    const index = units.indexOf(this.expenseUnit+'') + 1;
                    unitRefSelect.selectedIndex = index;
                }
            }
        },
        isExpenseDragged(newVal:boolean):void{
            const input = this.nameInputElement;
            if(input && !isMobile())
                Vue.nextTick(() => { input.focus(); input.select(); });

            if(newVal && isMobile()) 
                this.$set( this.expense, 'isDragged', false );
        },
        checkAgainstNames(names:string[]):void{
            try{
                const input:HTMLInputElement = this.nameInputElement;
                const tName = this.expense.name;
                let err;
                if(!names.includes(tName)) err = '';
                else err = `Повторяющееся название ${tName}`;
                input.setCustomValidity(err);
                // eslint-disable-next-line no-empty
            }catch(ignore){}
        },
        allReferences(newRefs:(ExpenseReference|NumberReference)[]):void{
            if(!(this.expense instanceof RefExpense)) return;
            const ref = this.expense.reference;
            let found:(ExpenseReference|NumberReference|undefined);
            if(!newRefs.includes(ref)){
                if(ref instanceof ExpenseReference) found = this.referencer.findReferenceByExpense(this.expense.reference.to)[0];
                else if(ref instanceof NumberReference) found = this.referencer.findReferenceByPath(this.expense.reference.path)[0];
                else console.warn({ref,expense:this.expense,msg:'RefExpense does not reference number of expense'});
            }
            this.expense.reference = found ? found : newRefs[0];
        },
        async expenseUnit(newUnit:string|undefined,oldVal:string|undefined):Promise<void>{
            if(!newUnit) return;
            let resetVal = false;
            if(newUnit=='editUnits'){
                resetVal = true;
                this.$modal.show('project-units');
            }
            await Vue.nextTick();
            const isUnit = this.expense instanceof UnitExpense;
            const isRef = this.expense instanceof RefExpense;
            
            if(resetVal && (isUnit || isRef)) (this.expense as any).unit = oldVal||'';
            
            // const type = isUnit?'unit': (isRef?'ref':'undef');
            // console.log({newUnit,name:this.expense.name,type,resetVal,expenseUnits:this.expenseUnits});
            
            // if(isUnit && (newUnit == '%' || newUnit == 'мес')) 
            //     this.expenseTypeSelect({target:{value:'ref'}});
            // else if(isRef && newUnit != '%' && newUnit != 'мес') 
            //     this.expenseTypeSelect({target:{value:'unit'}});
        },
        expenseName(newName){
            const matchingPreset = this.presetsForExpenseType.find(({name})=>name == newName);
            if(matchingPreset){ 
                if(this.nameInputElement)
                    this.nameInputElement.blur();
                this.expense.setType(matchingPreset.type);
            }
        },
    },
    methods: {
        //FIXME: Temp solution, should be replaced when draggable v-model is replaced with custom methods
        onDrag(isDrag:boolean):void{
            this.$forceUpdate();
        },
        selectUnit(e:{target:HTMLSelectElement}):void{
            const {target} = e;
            const index = target.selectedIndex - 1;
            const expUnit = this.expenseUnits[ index ];
            let unit = expUnit?.text;
            if(target.value == 'editUnits') unit = target.value;
            if(unit) this.$set(this.expense, 'unit', unit);
        },
        deleteExpense():void{
            this.$emit('delete-expense');
            this.expense.deleteExpense();
        },
        nameInputKeyPress(e:any):void{
            if(e.key=='Enter'){ 
                this.expense.isDragged=true;
                if(this.nameInputElement)
                    this.nameInputElement.blur();
            }
        },
        expenseClick():void{
            const addExpense = ()=>this.$emit('add-expense',this.expense)
            if(this.isAdd && this.expense.subExpenses!=undefined) 
                addExpense()
            if(this.expense.subExpenses == undefined){ 
                this.expense.changeSubExpenses(true)
                Vue.nextTick(addExpense)
            }
        },
        showProperty(name: string):boolean{
            if(this.isAdd) return false;
            const ue = this.expense instanceof UnitExpense,
            ce = this.expense instanceof CostExpense,
            re = this.expense instanceof RefExpense,
            hasParent = this.expense.parentExpense,
            uc = this.product.useCost,
            uu = this.product.useUnit;
            switch(name){
                case "name": return true;
                case "amount":
                case "unit": 
                    return uu &&(ue||re);
                case "reference": return uu&&re;
                case "pricePerUnit": return uu&&ue;
                case "switchSubExpense":
                case "type": return !hasParent;
                case "inputCost": return uc&&ce;
                case "expenseType": return ue||ce||re;
                default: {console.warn('unrecognized property '+name); return false;}
            }
        },
        async expenseTypeSelect(e:{target:{value:string}},v=''):Promise<void>{
            const str = (e&&e.target&&e.target.value) ? e.target.value : v;
            switch(str){
                case "cost": this.expense.changeToCostExpense(); break;
                case "unit": this.expense.changeToUnitExpense(); break;
                case "ref": this.expense.changeToRefExpense(); break;
                default: console.warn({expense:this.expense,msg:'Switching expense to unrecognized type '+e.target.value})
            }
            await Vue.nextTick();
            this.$forceUpdate();
        },
        checkSubExpenses(e:any):void{
            const addSubExpenses = e.target.checked
            this.expense.changeSubExpenses(addSubExpenses)
        },
        escapeRegExp
    },
    computed: {
        vueListExpenseContainer():any{
            return this.$refs.listExpenseContainer;
        },
        expense: {
            get():Expense{
                const expenses:Expense[] = this.product.expenses;
                const findExpense = expenses
                    .flatMap((e)=>e.withSubExpenses())
                    .find(({uuid})=>uuid == this.propExpense.uuid);
                return findExpense as Expense;
            },
            cache: false
        },
        nameInputElement():HTMLInputElement{
            return this.$refs.nameInput as HTMLInputElement;
        },
        presetsForExpenseType():Expense[]{
            // if(!(this as any).expensePresets) return [];
            const presets = (this as any).expensePresets;
            if(this.expense.type == -1) return presets;
            return presets.filter(({type}:any)=>type==this.expense.type)
        },
        isExpenseDragged():boolean{
            return this.expense.isDragged;
        },
        checkAgainstNames():string[]{
            const names = this.product.expenses.map((e:Expense)=>e.name);
            const index = names.indexOf(this.expense.name);
            if(index!=-1) names.splice(index,1);
            return names;
        },
        expenseName: {
            get():string|undefined{
                return this.expense.name;
            },
            cache: false
        },
        countCost():number{
            return this.expense.countCost();
        },
        displayCountCost():string{
            return numberWithSpaces(smartToFixed(this.countCost))+"";
        },
        isHandle():boolean{
            return this.expense.parentExpense == undefined && !this.isAdd && this.expense.isDragged;
        },
        expenseUnit:{
            get():string|undefined{
                return (this.expense as UnitExpense|RefExpense).unit;
            },
            cache: false
        },
        expenseUnitObj:{
            get():Unit|undefined{
                const unit = this.expenseUnit
                if(!unit) return;
                return this.expenseUnits.find(({text})=>text == unit);
            },
            cache: false
        },
        expenseUnits:{
            get():Unit[]{ return UnitManager.getUnitsForExpense(this.expense); },
            cache: false
        },
        allReferences:{
            get():(ExpenseReference|NumberReference)[]{
                return this.referencer.getReferences();
            },
            cache: false
        },
        safeReferences():(ExpenseReference|NumberReference)[]{
            const referencer:Referencer = this.referencer;
            if(this.expense instanceof RefExpense && [1,2].includes(this.expense.type)) 
                return referencer.safeExpenseReferences(this.expense);
            else if(this.expense instanceof RefExpense) 
                return referencer.allSafeReferences(this.expense);
            else return [];
        },
        expenseStrType:{
            get():'cost'|'ref'|'unit'|''{
                if(this.expense instanceof CostExpense) return 'cost'
                if(this.expense instanceof RefExpense) return 'ref'
                if(this.expense instanceof UnitExpense) return 'unit'
                return '';
            },
            cache: false
        },
        ...mapGetters(['product','channels','referencer','costErrors'])
    }
})
